import React from 'react';
import './leftPanel.css';
import { Link } from 'react-router-dom';

const LeftPanel = () => {
    return (
        <div className="left-panel">
            <nav className="left-nav" >
                <ul className="left-list" >
                    <Link to="/"><i className="fas fa-user"></i> Users </Link>
                    <Link to="/audios"><i className="fas fa-music"></i> Audios</Link>
                </ul>
            </nav>
        </div>
    )
}

export default LeftPanel
