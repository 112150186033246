//remote the '-template' from this file name to use it as intended. git ignore is set to ignore the keys.js file
//update keys template with new apis as they are created. 

export const apiKey = "AIzaSyBf3USXfUirUCjV-I53zf8SBBXYnExfrrs";
export const authDomain = "tantratimer.firebaseapp.com";
export const projectId = "tantratimer";
export const storageBucket = "tantratimer.appspot.com";
export const messagingSenderId = "961039528317";
export const appId = "1:961039528317:web:5cec9e337c97d991ecdc14";
export const measurementId = "G-SS5GRTTC66";
