import React, { useState, useEffect } from 'react'
import './users.css';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/firebaseConfig';
import UserStats from '../UserStats/UserStats';

const Main = () => {

    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);
    const getUsers = async () => {
        try {
            const data = await getDocs(collection(db, "Users"));
            console.log(data.docs);
            const dbUsers = [];
            data.forEach((doc) => {
                dbUsers.push(doc.data());
            });
            setUsers(dbUsers);
        } catch (error) {
            setError(error);
            console.log(error);
        }
       
    }

    useEffect(() => {
        getUsers();

    }, [])
    return (
        <div className="main-container">
            <UserStats users={users} error={error}/>

        </div>
    )
}

export default Main
