import React, { useState, useEffect } from 'react';
import './Audios.css';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db, storage } from '../../firebase/firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import SongsTable from '../SongsTable/SongsTable';

const Audios = () => {
    const [progressSong, setProgressSong] = useState(0);
    const [progressThumbnail, setProgressThumbnail] = useState(0);
    const [typeNames, setTypeNames] = useState(null);
    const [error, setError] = useState(null);
    const [songs, setSongs] = useState(null);
    const [mp3Name, setMp3Name] = useState('');
    const [author, setAuthor] = useState('');
    const [thumbnail, setThumbnail] = useState();
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [mp3File, setMp3File] = useState();
    const [mp3Url, setMp3Url] = useState('');
    const [isPro, setIsPro] = useState('No');
    const [errors, setErrors] = useState([]);
    const [isHidden, setIsHidden] = useState('No');
    const [typeNameSelected, setTypeNameSelected] = useState('');
    const [uploadSongError, setUploadSongError] = useState('');
    const [uploadThumbnailError, setUploadThumbnailError] = useState('');
    const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
    const [thumbnailIsUploaded, setThumbnailIsUploaded] = useState(false);
    const [isUploadingSong, setIsUploadingSong] = useState(false);
    const [songIsUploaded, setSongIsUploaded] = useState(false);
    const [songUploaded, setSongUploaded] = useState(false);
    const [isFormEditing, setIsFormEditing] = useState(false);
    const [songId, setSongId] = useState('');
    const [songUpdated, setSongUpdated] = useState(false);
    const [songIsDeleting, setSongIsDeleting] = useState(false);
    const [duration, setDuration] = useState('');
    const [genres, setGenres] = useState(null);
    const [selectedGenre, setSelectedGenre] = useState('');

    const uploadSongFile = (e) => {
        e.preventDefault();
        setIsUploadingSong(true);
        if(mp3Name === ""){
            setErrors([...errors, setErrors("Please provide a name for the audio file")]);
            setIsUploadingSong(false);
            return;
        }
        const storageRef = ref(storage, `/audios/${mp3File.name}`);
        const uploadTask = uploadBytesResumable(storageRef, mp3File);

        uploadTask.on("state_changed", (snapshot) => {      
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100;
            setProgressSong(prog);
        }, (err) => console.log(err), () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url)
                setMp3Url(url)
                setIsUploadingSong(false);
                setSongIsUploaded(true);
            }).catch((error) => {
                setUploadSongError(error);
                setIsUploadingSong(false);
            });
        } )
    }

    const uploadThumbnail = (e) => {
        e.preventDefault();
        setIsUploadingThumbnail(true);
        console.log(thumbnail);
        const storageRef = ref(storage, `/images/${thumbnail.name}`);
        const uploadTask = uploadBytesResumable(storageRef, thumbnail);

        uploadTask.on("state_changed", (snapshot) => {      
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100;
            console.log(snapshot.bytesTransferred,  snapshot.totalBytes);
            setProgressThumbnail(prog);
        }, (err) => console.log(err), () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setThumbnailUrl(url);
                setIsUploadingThumbnail(false);
                setThumbnailIsUploaded(true);
            })
            .catch((error) => {
                setUploadThumbnailError(error);
                setIsUploadingThumbnail(false);
            });
        } )
    }

    const addSong = async (e) => {
        e.preventDefault();
        const song = {
            author,
            duration,
            pro: isPro ? true : false,
            hidden: isHidden ? true : false,
            mp3Name,
            thumbnail: thumbnailUrl,
            trendingScore: Math.floor(Math.random() * 20) * Math.floor(Math.random() * 20) ,
            typeName: typeNameSelected,
            mp3Url,
            genre: selectedGenre,
        };

        if(author === ''){
            setErrors([...errors, 'Author needs to be provided']);
            return;
        }
        if(mp3Name === ''){
            setErrors([...errors, 'Song name needs to be provided']);
            return;
        }
        if(mp3Url === ''){
            setErrors([...errors, 'Song file needs to be uploaded']);
            return;
        }
        if(thumbnail === ''){
            setErrors([...errors, 'Thumbnail file needs to be uploaded']);
            return;
        }
        if(typeNameSelected === ''){
            setErrors([...errors, 'Type name needs to be provided']);
            return;
        }
        if(duration === ''){
            setErrors([...errors, 'Duration needs to be provided' ])
        }
        if(selectedGenre === ''){
            setErrors([...errors, 'Please select a genre' ])
        }

       const response = await addDoc(collection(db, "Songs"), song);
       console.log(response);
        getSongs();
        setSongUploaded(true);
        setTimeout(() => {
            setSongUploaded(false);
        }, 4000)

        resetForm();
        getSongs();
    }

    const getTypeNames= async () => {
        try {
            const data = await getDocs(collection(db, "Type"));
            console.log(data.docs);
            const dbTypes = [];
            data.forEach((doc) => {
                dbTypes.push(doc.data());
            });
            setTypeNames(dbTypes);
            console.log(dbTypes);
        } catch (error) {
            setError(error);
        }
    }

    const getGenres = async () => {
        try {
            const data = await getDocs(collection(db, "Genre"));
            console.log(data.docs);
            const dbGenres = [];
            data.forEach((doc) => {
                dbGenres.push(doc.data());
            });
            setGenres(dbGenres);
            console.log(dbGenres);
        } catch (error) {
            setError(error);
        }
    
    }



    const getSongs = async () => {
        try {
            const data = await getDocs(collection(db, "Songs"));
            //console.log(data.docs);
            const dbSongs = [];
            data.forEach((doc) => {
                console.log(doc.id)
                dbSongs.push({id: doc.id , ...doc.data()});
            });
            setSongs(dbSongs);
            console.log(dbSongs)
        } catch (error) {
            setError(error);
        }
    
    }

    useEffect(() => {
        getTypeNames();
        getGenres();
        getSongs();
    }, [])

    const handleEnableEdit = async (song) => {
        setIsFormEditing(true);
        setMp3Name(song.mp3Name);
        setAuthor(song.author);
        setMp3Url(song.mp3Url);
        setThumbnailUrl(song.thumbnail);
        setTypeNameSelected(song.typeName);
        setSelectedGenre(song.genre);
        if(song.hidden){
            setIsHidden("Yes");
        }
        if(song.hidden === false){
            setIsHidden("No");
        }
        if(song.pro){
            setIsPro("Yes");
        }
        if(song.pro === false){
            setIsPro("No");
        }

        setSongId(song.id);
    }

    const edit = async (e) => {
        e.preventDefault();

        try {
            updateDoc(doc(db, "Songs", songId ), {
                author: author,
                mp3Name: mp3Name,
                typeName: typeNameSelected,
                pro: isPro,
                hidden: isHidden,
                thumbnail: thumbnailUrl,
                mp3Url: mp3Url,
                duration: duration,
                genre: selectedGenre,
            })

            setSongUpdated(true);
            setTimeout(() => {
                setSongUpdated(false);
            }, 4000)
            
        } catch (error) {
            setErrors([...errors, 'error editing']);
        }

        resetForm();
        getSongs();
     }

    const handleDelete = (id) => {
        try {
          deleteDoc(doc(db, "Songs", id));
          setSongIsDeleting(true);
          getSongs();
          setErrors([]);
          setTimeout(() => {
            setSongIsDeleting(false);
        }, 4000)
        } catch (error) {
            setErrors([...errors, "There was an error while deleting"]);
            console.log(error);
        }     
    }

    const resetForm = () => {
        setAuthor('');
        setMp3Name('');
        setProgressSong(0);
        setProgressThumbnail(0);
        setSongId('');
        setTypeNameSelected('');
        setIsHidden('No');
        setIsPro('No');
        setThumbnailUrl('');
        setThumbnail('');
        setIsFormEditing(false);
        setSongUpdated(false);
        setErrors([]);
        setThumbnailIsUploaded(false);
        setSongIsUploaded(false);
        setSongIsDeleting(false);
        setDuration('');
        setSelectedGenre('');
    }

    return (
        <div>
            <div className="songs-list-wrapper">
                <SongsTable 
                   handleEdit={(song) => handleEnableEdit(song)} 
                   handleDelete={(song) => handleDelete(song)} 
                   songs={songs} 
                   error={error} 
                   isDeleting={songIsDeleting}
                />
            </div>
           <div className="login-audio-form">  
                <form className='subForm' >
                    <h1 className="login-audio-title">{ isFormEditing ? "Edit song" : "Add MP3 file"}</h1>
                    <div className="login-audio-inputs">
                        <div className="input-group">
                            <label className="login-audio-label">Mp3 name</label>
                            <input placeholder='song name' value={mp3Name}  onChange={(e) => setMp3Name(e.target.value)} type="text" name="title"  />
                        </div>   
                        <div className="input-group">
                            <label className="login-audio-label">Author</label>
                            <input placeholder='e.g. Michael Jackson' value={author} onChange={(e) => setAuthor(e.target.value)}  type="text" name="author"  />
                        </div>
                        <div className="input-group">
                            <label className="login-audio-label">Duration</label>
                            <input placeholder='5:20 format' value={duration} onChange={(e) => setDuration(e.target.value)}  type="text" name="duration"  />
                        </div>
                        <div className="input-group">
                            <label className="login-audio-label">Pro ?</label>
                            <div className="audio-radios">
                                <input checked={isPro === "Yes" ? true : false} onChange={(e) => setIsPro(e.target.value)} type="radio" id="proYes" name="pro" value="Yes" />
                                <label htmlFor="proYes">Yes</label>

                                <input checked={isPro === "No" ? true : false} onChange={(e) => setIsPro(e.target.value)} type="radio" id="proNo" name="pro" value="No"/>
                                <label htmlFor="proNo">No</label>
                            </div>
                        </div>
                        <div className="input-group">
                            <label className="login-audio-label">Hidden ?</label>
                            <div className="audio-radios">
                                <input checked={isHidden === "Yes" ? true : false} onChange={(e) => setIsHidden(e.target.value)} type="radio" id="hiddenYes" name="hidden" value="Yes" />
                                <label htmlFor="hiddenYes">Yes</label>
                                <input checked={isHidden === "No" ? true : false} onChange={(e) => setIsHidden(e.target.value)} type="radio" id="hiddenNo" name="hidden" value="No"/>
                                <label htmlFor="hiddenNo">No</label>
                            </div>
                        </div>    
                        <div className="input-group">
                            <label>Select a category for the song</label>
                            <select onChange={(e) => setTypeNameSelected(e.target.value)} name="type" className="types-select">
                                {
                                    typeNames !== null ? (  typeNames.map(type => (
                                        <option value={`${type.typeName}`}>{type.typeName}</option>
                                    ))) : <p>{error}</p>
                                }
                            </select>         
                        </div>
                        <div className="input-group">
                            <label>Select a genre for the song</label>
                            <select onChange={(e) => setSelectedGenre(e.target.value)} name="type" className="types-select">
                                {
                                    genres !== null ? (  genres.map(genre => (
                                        <option value={`${genre.genreName}`}>{genre.genreName}</option>
                                    ))) : <p>{error}</p>
                                }
                            </select>         
                        </div>
                    </div>
                </form>
                <form className='subForm'  onSubmit={(e) => uploadThumbnail(e)} >     
                    <div className="input-group">
                        <label className="login-audio-label">Thumbnail</label>
                        <input value={thumbnail} onChange={(e) => setThumbnail(e.target.files[0])}  className="input-file" type="file" name="thumbnail" accept="image/*"/>
                        <button className='upload-file' type='submit'>Upload Thumbnail</button>
                        <p>{progressThumbnail} %</p>
                        { uploadThumbnailError && <p style={{color: 'red', fontWeight: 'bold'}} >An error ocurred while uploading thumbnail</p>}
                        { isUploadingThumbnail && <p>uploading thumbnail...</p> }
                        { thumbnailIsUploaded && <p style={{color: 'green', fontWeight: 'bold'}}> thumbnail file uploaded successfully </p> }
                    </div>
                </form>
                <form className='subForm' onSubmit={(e) => uploadSongFile(e)} >
                    <div className="input-group">
                        <label className="login-audio-label">Mp3 file</label>
                        <input value={mp3File} onChange={(e) => setMp3File(e.target.files[0])} className="input-file" type="file" name="mp3" accept="audio/*"/>
                        <button className='upload-file' type='submit'>Upload song</button>
                        <p>{progressSong} %</p>
                        { uploadSongError && <p style={{color: 'red', fontWeight: 'bold'}} >An error ocurred while uploading song</p>}
                        { isUploadingSong && <p>uploading song...</p> }
                        { songIsUploaded && <p style={{color: 'green', fontWeight: 'bold'}}> Song file uploaded successfully </p> }
                    </div>
                </form>
                <form className='subForm' onSubmit={(e) => {isFormEditing ? edit(e) : addSong(e)} }>
                    <button className="login-audio-btn" type="submit">{ isFormEditing ?"Edit song" :  "Add song" } </button>
                </form>
                {
                    errors && errors.map(err => (
                        <p className='error'> {err} </p>
                    )) 
                }
                {
                    songUploaded && <p style={{color: 'green', fontWeight: 'bold'}}> Song uploaded successfully </p>
                }
                {
                    songUpdated  && <p style={{color: 'green', fontWeight: 'bold'}}> Song updated successfully </p>
                }
            </div> 
        </div>
        
    )
}

export default Audios
