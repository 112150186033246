import React, { useEffect, useState} from 'react'
import './userStats.css'
const UserStats = ({users, error}) => {

    const [payedUsers, setPayedUsers] = useState(null)

    useEffect(() => {
        setPayedUsers(users?.filter(user => user.status === true))
    }, [users])

    return (
        <div className="users-stats">
            {
                users !== null ? (
                    <div className="users-stats-content">
                         <div className="users-numbers">
                            <h2>Total Records <span>{users?.length}</span> </h2>
                            <h2>Total Payed <span>{payedUsers?.length}</span> </h2>
                         </div>
                
                        <div className="users-list">
                            <div className="users-headings">
                                <div className="heading">
                                    <p>Username</p> 
                                </div>
                                <div className="heading">
                                   <p>Email</p>  
                                </div>
                                <div className="heading">
                                   <p> Phone</p>
                                </div>
                                <div className="heading">
                                   <p>Status</p> 
                                </div>
                                <div className="heading">
                                   <p>Renewal date</p> 
                                </div>
                            </div>
                            <div className="users-rows">
                              {
                                users ? (
                                    users.map(user => (
                                        <div className="user-row">
                                            <div className="user-cell">
                                               <p>{user.username}</p> 
                                            </div>
                                            <div className="user-cell">
                                               <p> {user.email}</p>
                                            </div>
                                            <div className="user-cell">
                                                <p>{user.phone}</p> 
                                            </div>
                                            <div className="user-cell">
                                               <p>{user.status ? 'Member' : 'Free user' }</p> 
                                            </div>
                                            <div className="user-cell">
                                               <p>{user.renewal?.seconds}</p> 
                                            </div>
                                        </div>
                                    ))
                                ) 
                                : <p>{error}</p> 
                              }
                            </div>
                            
                        </div>
                    </div>
                   
                ) : <p> {error} </p>
            }
            
        </div>
    )
}

export default UserStats
