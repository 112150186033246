import React, {useState} from 'react';
import './login.css';
import logoTantra from '../../assets/tantra-icon.svg';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Login = ({ loginHandler, handlePassword, handleUserName, password, email, isLoading, errorPass, errors  }) => {
    const [showForgot, setShowForgot] = useState(false);
    const [newEmail, setNewEmail] = useState(null);
    const [errorSending, setErrorSending] = useState('');
    const [success, setSuccess] = useState('');
    const [emailError, setEmailError] = useState('');
    const auth = getAuth();

    const resetPassword = (e) => {
        e.preventDefault();
        setErrorSending('');
        setSuccess('');
        if(email.length === 0) {
            setEmailError('Please fill in email');
        }
        sendPasswordResetEmail(auth, email ? email : newEmail)
        .then(() => {
          setSuccess('Email sent');
        })
        .catch((error) => {
            if(error.message == 'Firebase: Error (auth/user-not-found).')
          setErrorSending('Email does not belong to any user');
        });
        setNewEmail('');
      }

    return (
        <div className="form-container">
            {
              showForgot && <button onClick={() => setShowForgot(false)}>Go Back</button>
            }
            { !showForgot ? (
                        <form onSubmit={(e) => loginHandler(e)} className="login-form" >
                            <img className="form-logo" src={logoTantra} alt="" />
                            <h1 className="login-title">Login</h1>

                            <div className="login-inputs">
                                <label className="login-label">Username</label>
                                <input onChange={handleUserName} type="text" name="username" value={email}  />
                                
                                <label className="login-label">Password</label>
                                <input onChange={handlePassword} type="password" name="username" value={password}  />
                            </div>

                            <button className="login-btn" type="submit">Login</button>
                            {
                                isLoading && <p> Loading...</p>
                            }
                        </form>
                    ) : ( 
                        <form className='forgot-form' onSubmit={(e) => resetPassword(e)}>
                            <h2>Reset your password</h2>
                            <div className="login-inputs">
                                <label className="login-label">Email</label>
                                <input onChange={(e) => setNewEmail(e.target.value)} type="text" name="username" value={email ? email :  newEmail}  />
                            </div>
                            <button className="login-btn" type='submit'>Send email</button>
                            {
                                isLoading && <p> Loading...</p>
                            }
                            {
                                success && <p> Email sent successfully...</p>
                            }
                            {
                                emailError && <p> An error ocurr with the email</p>
                            }
                            {
                                errorSending && <p> An error ocurr on this request</p>
                            }
                        </form> 
                )
            }
            {
                errorPass && ( <p style={{ color: 'red' }}> Password incorrect </p> ) 
            }
            {
                errors && errors.map(err => (
                    <p className='error'> {err} </p>
                ))
            }
            {
               !showForgot && <button className="forgot-btn" onClick={() => setShowForgot(true)}>Forgot password?</button>
            }
        </div>
            
    )
}

export default Login
