import './App.css';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import React, { useState, useEffect } from 'react';
import { app, db } from './firebase/firebaseConfig';
import { 
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection,getDocs, query, where } from "firebase/firestore";

function App() {

  //const provider = new GoogleAuthProvider();
  const auth = getAuth(app);

  //const [isLoggin, setIsLoggin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [wrongPass, setWrongPass] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
    
      if(user) {
        const userFirebase = {
          uid: user.uid,
          email: user.email
        }
        setCurrentUser(userFirebase);
      } else {
        setCurrentUser(null)
      }
    })
  }, [auth])

  const login = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let shouldLogin = true;  

      //First check if role is admin
      const q = query(collection(db, "Users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

      if(!doc.exists()){
        setErrors([...errors, "Not existing user"])
      }    
      if(doc.data().role === "user"){
          setErrors([...errors, "This user is not an admin"])
          shouldLogin = false;
      }
      });

      if(shouldLogin){
        
          try {
            const userFirebase = await signInWithEmailAndPassword(auth, email, password);
            const userData = {
              uid: userFirebase.user.uid,
              email: userFirebase.user.email
            };
      
            setCurrentUser(userData);
          } catch (error) {
              if(error.code === "auth/invalid-email" || error.code === "auth/invalid-password" ){
                setErrors([...errors, "Wrong credentials"]);
              }
          }
      }

    } catch (error) {
      if(error.code === "auth/invalid-email" || error.code === "auth/invalid-password" ){
        setErrors([...errors, "Wrong credentials"]);
      }
    }
    
    setIsLoading(false);
    setEmail('');
    setPassword('');
    
  }
  
  const handleUserName = (e) => {
        const value = e.target.value;
        setEmail(value);   
    }

    const handlePassword = (e) => {
        const value = e.target.value;
        setPassword(value);  
    }

  return (
    <div className="App">

      {
       currentUser 
       ? ( <Home user={currentUser} /> )   
       : <Login loginHandler={login} 
               handleUserName={handleUserName}
               handlePassword={handlePassword}
               password={password}
               email={email}
               isLoading={isLoading}
               errorPass={ wrongPass}
               errors = {errors}
        />
      }
    </div>
  );
}

export default App;
