import React from 'react';
import './home.css';
import LeftPanel from '../LeftPanel/LeftPanel';
import Users from '../Users/Users';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Audios from '../Audios/Audios';

const Home = ({ user }) => {
    return (
        <div className="home">  
        <Router> 
            <div className="top-panel">
                <h1>Tantra Timer Admin Panel</h1>
                <h2> {user.uid} </h2>
                <h3> {user.email}</h3>
            </div>
            <div className="app-container">
                <LeftPanel/>
                <div className="app-content">  
                    <Routes>
                        <Route exact path="/" element={<Users/>}/>
                        <Route exact path="/audios" element={<Audios/>}/>
                    </Routes> 
                </div>
            </div>
         </Router>    
        </div>

    )
}

export default Home
