import React from 'react'
import './songsTable.css'
const UserStats = ({songs, error, handleDelete, handleEdit, isDeleting}) => {

    return (
        <div className="users-stats">
            {
                songs !== null ? (
                    <div className="users-stats-content">
                         <div className="users-numbers">
                            <h2>Total Records <span>{songs?.length}</span> </h2>
                         </div>
                
                        <div className="users-list">
                            <div className="users-headings">
                                <div className="heading">
                                    <p>Thumbnail</p> 
                                </div>
                                <div className="heading">
                                    <p>Title</p> 
                                </div>
                                <div className="heading">
                                   <p>Author</p>  
                                </div>
                                <div className="heading">
                                   <p> Mp3</p>
                                </div>
                                <div className="heading">
                                   <p>Pro ?</p> 
                                </div>
                                <div className="heading">
                                   <p>Trending Score</p> 
                                </div>
                                <div className="heading">
                                    <p>Edit</p>
                                </div>
                                <div className="heading">
                                    <p>Delete</p>
                                </div>

                            </div>
                            <div className="users-rows">
                              {
                                songs ? (
                                    songs.map(song => (
                                        <div className="user-row">
                                            <div className="user-cell">
                                                <div className="song-thumbnail" 
                                                     style={{backgroundImage: `url(${song.thumbnail})`, backgroundSize: 'cover' }}
                                                >

                                                </div>
                                            </div>
                                            <div className="user-cell">
                                               <p>{song.mp3Name}</p> 
                                            </div>
                                            <div className="user-cell">
                                               <p> {song.author}</p>
                                            </div>
                                            <div className="user-cell">
                                                <audio className="song-audio" controls="controls">
                                                    <source src={`${song.mp3Url}`}/>
                                                </audio>
                                            </div>
                                            <div className="user-cell">
                                               <p>{song.pro ? 'Pro' : 'Free song' }</p> 
                                            </div>
                                            <div className="user-cell">
                                               <p>{song.trendingScore}</p> 
                                            </div>
                                            <div className="user-cell">
                                               <button onClick={() => handleEdit(song)}>Edit</button>
                                            </div>
                                            <div className="user-cell">
                                               
                                               {
                                                    isDeleting 
                                                    ?  <p style={{color: 'green', fontWeight: 'bold'}}> Deleting... </p> 
                                                    : <button onClick={() => handleDelete(song.id)}>Delete</button> 
                                               }
                                            </div>
                                        </div>
                                    ))
                                ) 
                                : <p>{error}</p> 
                              }
                            </div>
                            
                        </div>
                    </div>
                   
                ) : <p> {error} </p>
            }
            
        </div>
    )
}

export default UserStats
